
import Vue from 'vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MallBanner',
  props: {
    /**
     * Card <Props>
     * chip : 칩 사용 여부
     * chipContent : 칩 내용  ( TODO 칩 사용 여부를 이걸로 대체 가능 )
     * image : 이미지
     */
    chip: {
      type: Boolean,
      default: false,
    },
    chipContent: {
      type: Number,
      default: null,
    },
    image: {
      // 이미지
      type: String,
      default: '',
    },
    image2: {
      // Text Image
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    shopId: {
      type: String,
      default: '',
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  methods: {
    moveToStore(shopId: string) {
      // storeHome 가기전 리턴 url 저장
      if (this.gtagName !== '') {
        this.$tracking(this.gtagName + '_shop')
      }
      this.$router.push({ name: 'StoreHome', query: { shopId: this.shopId } })
    },
  },
})
interface Data {}
interface Methods {}
interface Computed {}
interface Props {
  chip: boolean
  chipContent: number | null
  image: string
  image2: string
  active: boolean
  shopId: string
  gtagName: string
}
