import { render, staticRenderFns } from "./MallBannerCard.vue?vue&type=template&id=6a24ab98&scoped=true&"
import script from "./MallBannerCard.vue?vue&type=script&lang=ts&"
export * from "./MallBannerCard.vue?vue&type=script&lang=ts&"
import style0 from "./MallBannerCard.vue?vue&type=style&index=0&id=6a24ab98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a24ab98",
  null
  
)

export default component.exports