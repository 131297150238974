
import Vue from 'vue'
import ProductCardX from '../../../common/widgets/ProductCardX.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'

export default Vue.extend({
  name: 'CategoryTopShopListCarousel',
  components: { VueSlickCarousel, ProductCardX },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    touchless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselOption: {
        centerMode: true,
        centerPadding: '10px',
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        swipeToSlide: true,
      },
      active: 0,
    }
  },
  watch: {
    item() {
      const slickCarousel: any = this.$refs.slickCarousel
      slickCarousel.goTo(0)
    },
  },
  methods: {
    handleActive(active: number) {
      this.active = active
    },
    goStoreHome(shopId: string) {
      this.$router.push({ name: 'StoreHome', query: { shopId: shopId } })
    },
  },
})

interface Data {
  carouselOption: SlickOption
  active: number
}
interface Methods {
  handleActive(active: number): void
  goStoreHome(shopId: string): void
}
interface Computed {}
interface Props {
  item: CategoryTopShopItem
  touchless: boolean
}
