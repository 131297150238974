
import Vue from 'vue'
import CategoryTopShopListView from '../../views/category/CategoryTopShopList.vue'
import { mapActions } from 'vuex'
import { getHistory } from '@/helper/category'

export default Vue.extend<Data, Methods, Computed>({
  components: {
    CategoryTopShopListView,
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('CategoryStore', [
      'fetchInitCategoryHistory',
      'fetchCategoryHistory',
      'fetchCategoryTopShop',
    ]),
    ...mapActions('CategoryStore', ['fetchCategoryTopShop']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    async fetchData() {
      try {
        this.fetchLoading({ yn: true })
        const history: CategoryHistory | null = getHistory()
        if (history && history.page.currentPage === this.$route.path.slice(1)) {
          this.fetchCategoryHistory(history)
        } else {
          this.fetchInitCategoryHistory()
          await this.fetchCategoryTopShop()
        }
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  fetchInitCategoryHistory(): Promise<void>
  fetchCategoryTopShop(): Promise<void>
  fetchLoading(payload: { yn: boolean }): Promise<void>
  // Component
  fetchData(): void
}
interface Computed {}
