
import Vue from 'vue'
import MallBannerCard from '../../../common/widgets/MallBannerCard.vue'
import CategoryTopShopListCarousel from './CategoryTopShopListCarousel.vue'
import { mapActions, mapGetters } from 'vuex'
import CellookButton from '@/components/common/buttons/CellookButton.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CategoryProductList',
  components: {
    CellookButton,
    MallBannerCard,
    CategoryTopShopListCarousel,
  },
  props: {
    touchless: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    this.fetchCategoryHistory({
      page: { currentPage: this.$route.path.slice(1) },
    })
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters('HomeStore', ['getNewInHistory']),
    ...mapGetters('CategoryStore', ['getCategoryTopShop']),
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('HomeStore', ['fetchNewInHistory', 'fetchMainTab']),
    ...mapActions('CategoryStore', [
      'fetchScrollLocation',
      'fetchCategoryHistory',
    ]),
    pageMoveNewIn() {
      this.fetchMainTab({ tab: 1 })
      this.$router.push('/')
    },
    handleScroll() {
      this.fetchCategoryHistory({
        page: { scrollLocation: this.$getAppHtml.scrollTop },
      })
    },
    goStoreTab() {
      this.fetchMainTab({ tab: 1 })
      this.$router.push('/')
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchMainTab(payload: { tab: number }): Promise<void>
  fetchNewInHistory(payload: NewInHistoryPayload): Promise<void>
  fetchScrollLocation(payload: number): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  // Component
  pageMoveNewIn(): void
  handleScroll(): void
  goStoreTab(): void
}
interface Computed {
  getCategoryTopShop: CategoryTopShop
  getNewInHistory: NewInHistoryPayload
}
interface Props {
  touchless: boolean
}
