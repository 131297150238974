
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import MallBanner from '@/components/common/cards/MallBanner.vue'
import ProductCardX from '@/components/common/widgets/ProductCardX.vue'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import Alert from '@/components/common/dialogs/Alert.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { MallBanner, ProductCardX, Alert },
  props: {
    image: {
      type: String,
      default: '',
    },
    image2: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    chipContent: {
      type: Number,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1,
    },
    images: {
      type: Array,
      default: () => [],
    },
    shopId: {
      type: String,
      default: '',
    },
    shopName: {
      type: String,
      default: '',
    },
    isSubscribed: {
      type: Boolean,
      default: false,
    },
    useProductList: {
      type: Boolean,
      default: true,
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: this.isSubscribed,
      count: this.chipContent,
      alert: {
        show: false,
        message: '',
      },
    }
  },
  watch: {
    refreshNoti(yn) {
      this.active = this.isSubscribed ?? false
    },
    isSubscribed(yn) {
      this.active = yn
    },
    chipContent(val) {
      this.count = val
    },
  },
  computed: {
    ...mapState('PullToRefreshStore', ['refreshNoti']),
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    async handleActive() {
      if (!this.isLogined) return this.fetchLoginDialog({ yn: true })

      if (this.gtagName !== '') {
        this.$tracking(this.gtagName + '_sub')
      }
      !this.active ? await this.subscribe() : await this.unSubscribe()
    },
    async subscribe() {
      if (!this.shopId) return
      try {
        await PostSubscribe(this.shopId, this.shopName)
        if (!this.gtagName) this.$tracking(`${this.gtagName}_subscribe`)
        this.active = true
        this.count += 1
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe() {
      if (!this.shopId) return
      try {
        await DeleteSubscribe(this.shopId, this.shopName)
        if (!this.gtagName) this.$tracking(`${this.gtagName}_unsubscribe`)

        this.active = false

        this.count -= 1
      } catch (e: any) {
        console.log(e.response)
      }
    },
    isLikeChange(productNo, isLike, index) {
      if (!this.gtagName) return
      this.$emit('isLikeChange', productNo, isLike)
      const trackingName = `${this.gtagName}_${this.$numbering(index)}`
      if (isLike) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})

interface Data {
  active: boolean
  count: number
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  fetchLoginDialog({ yn }: { yn: boolean }): void
  handleActive(): void
  subscribe(): void
  unSubscribe(): void
  isLikeChange(productNo: string, isLike: boolean, index: number): void
}
interface Computed {
  refreshNoti: boolean
}
interface Props {
  image: string
  image2: string
  title: string
  subTitle: string
  content: string
  chipContent: number
  aspectRatio: number
  images: ProductItem[]
  shopId: string
  isSubscribed: boolean
  useProductList: boolean
  gtagName: string
  shopName: string
}
