
import Vue from 'vue'
import CategoryHeader from './common/CategoryHeader.vue'
import CategoryTopShop from './common/CategoryTopShop.vue'
import { mapGetters, mapActions } from 'vuex'
import { onRequestAdonBanner } from '@/helper/banner'

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryTopShopListView',
  components: {
    CategoryHeader,
    CategoryTopShop,
  },
  data() {
    return {
      touchless: false,
    }
  },
  watch: {
    isLogined() {
      this.handleRefresh()
    },
  },
  computed: {
    ...mapGetters('CategoryStore', ['getCategoryTopShop']),
  },
  methods: {
    ...mapActions('CategoryStore', ['fetchCategoryTopShop']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    async handleRefresh() {
      this.fetchLoading({ yn: true })
      if (this.$route.name) onRequestAdonBanner(this.$route.name)
      await this.fetchCategoryTopShop()
      this.fetchLoading({ yn: false })
    },
    pullToState(yn: boolean) {
      this.touchless = yn
    },
  },
})

interface Data {
  touchless: boolean
}
interface Methods {
  // Store
  fetchCategoryTopShop(): Promise<void>
  fetchLoading(payload: { yn: boolean }): Promise<void>
  // Component
  handleRefresh(): void
  pullToState(yn: boolean): void
}
interface Computed {
  getCategoryTopShop: CategoryTopShop
}
